.process-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--goto-spacing-04);
  border-radius: 4px;
  padding: var(--goto-spacing-04);

  &__loader-icon {
    --goto-icon-color: var(--goto-icon-color-brand-default);

    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--goto-spacing-02);
  }

  &--initial {
    background: var(--goto-surface-color-secondary);
  }

  &--running {
    background: var(--goto-surface-color-secondary);
  }

  &--selected {
    background: var(--goto-surface-color-utility-purple);
  }

  &--completed {
    background: var(--goto-surface-color-success-secondary);
  }

  &__titles {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    height: 20px;
  }

  &__description {
    height: 20px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--goto-spacing-02);
  }
}

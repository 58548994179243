@import '@getgo/chameleon-core/css/gotoresolve.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'SF Pro Display Regular';
  src: 'SFProDisplay-Regular.eot' format('embedded-opentype'),
       'SFProDisplay-Regular.eot?#iefix' format('embedded-opentype'),
       'SFProDisplay-Regular.woff' format('woff'),
       'SFProDisplay-Regular.ttf' format('truetype');
  font-weight: normal;
  font-style: normal;
}

.sf-font::part(text) {
  font-family: 'SF Pro Display Regular';
}

.container {
  padding: var(--goto-layout-02);
}

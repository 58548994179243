.discovery {
  display: flex;
  flex-direction: column;
  padding-top: 10.2rem;
  align-items: center;

  & > svg > path {
    fill: var(--goto-icon-color-default);

    &:nth-of-type(2) {
      fill: var(--goto-link);
    }
  }

  &__progress {
    width: 12.1rem;
    margin: var(--goto-layout-01);
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 460px;
  }

  &__paragraph {
    text-align: center;

    &::part(text) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

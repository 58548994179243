.page-header {
  &__controls-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--goto-spacing-03);
  }

  &__controls {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: var(--goto-spacing-04);
    }
  }

  &__heading {
    margin-bottom: var(--goto-spacing-04);
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: var(--goto-spacing-02) 0;

  &__link {
    margin-left: var(--goto-spacing-04);
  }

  & > * {
    color: var(--goto-icon-color-default);
  }
}

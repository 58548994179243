.report-result {
  margin-bottom: 0;
}

.report-list {
  list-style-type: '-';
  padding-inline-start: 0;
  margin-block-start: 0;

  &__item {
    padding-left: 0.5em;
  }
}
.report-without-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 1em;
  }
}
.report-data {
  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * + * { margin-left: 0.5em; }
  }
  .icon-danger { --goto-icon-color: var(--goto-icon-color-danger); }
  .icon-warning { --goto-icon-color: var(--goto-icon-color-warning); }
  .icon-success { --goto-icon-color: var(--goto-icon-color-success); }
  chameleon-data-table { width: 100%; margin-top: 1em; }
}

.report-data header {
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
  & div {
    width: 50%;
  }
  & div + div {
    margin-left: 1em;
  }
}

.report-data main div + div {
  margin-top: 1em;
}

.report-protection-status chameleon-data-table-cell-v2 + chameleon-data-table-cell-v2 {
  width: 60%;
}

.button-extra-space {
  margin-right: auto;
}

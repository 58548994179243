.license-wraning-content {
  margin-bottom: var(--goto-spacing-07);
  & > .license-warning-content__license-counter ~ * {
    margin-bottom: var(--goto-spacing-01);
  }
}
.license-warning-content__license-counter {
  display: flex;
  width: 14.5rem;
  margin: 1rem 0;
  & > div {
    flex-grow: 1;
    margin-left: var(--goto-spacing-03);
  }
  & chameleon-linear-progress[aria-valuenow='100']::part(determinate) {
    background-color: var(--goto-red-500);
  }
}

.antivirus-table-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.antivirus-table-filters {
  user-select: none;
  &__item + &__item {
    margin-left: var(--goto-spacing-02);
  }
}

.antivirus-table-search-field {
  position: absolute;
  right: 0;
}

@media (max-width: 60em) {
  .antivirus-table {
    width: max-content;
    overflow-y: hidden;
  }

  .antivirus-table-search-field {
    position: static;
  }
}
